import loadable from "@loadable/component"
const MarketWatch = loadable(() => import("./MarketWatch"))
// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.

const allModules: any[] = [
  { name: "MarketWatch", module: MarketWatch },
]

export const getModule = (moduleName: string) => {
  if (!moduleName) return null
  const obj = allModules.find(
    m => m.name.toLowerCase() === moduleName.toLowerCase()
  )
  if (!obj) return null
  return obj.module
}